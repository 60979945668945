import { Card } from "semantic-ui-react";

export default function Instruction() {
  return (
    <div className="instruction-card">
      <Card centered fluid className="card">
        <Card.Header as={"h1"} textAlign={"center"}>
          Fyll i formuläret nedan så kontaktar vi dig!
        </Card.Header>
        <Card.Description as={"p"}>
          Det går också bra att ringa direkt på{" "} 
          <a href="tel:+46737008002">+46-73 700 800 2</a>.<br/>
          Värderingen är helt gratis inom 20 minuter och du binder dig ej till något!
        </Card.Description>
      </Card>
    </div>
  );
}
