import ContactForm from "./components/ContactForm";
import HeroSection from "./components/HeroSection";
import Instruction from "./components/Instruction";
export default function App() {
  return (
    <div className="App">
      <HeroSection />
      <Instruction />
      <ContactForm />
    </div>
  );
}
