import { scroller } from "react-scroll";
import hero from "../assets/images/hero.jpg";
import { FaArrowCircleDown } from "react-icons/fa";
export default function HeroSection() {
  const scrollToSection = () => {
    scroller.scrollTo("form", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  return (
    <div className="hero">
      <img src={hero} alt="" />
      <section className="slogan">
        <h1>Vi Köper Alla Bilar</h1>
        <p>Begagnade, Trasiga och Defekta ...</p>
      </section>
      <FaArrowCircleDown onClick={scrollToSection} className="arrow jump" />
      <div className="shadow" />
    </div>
  );
}
